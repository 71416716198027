import Contract, { contractNextStep } from "@/functions/src/models/contract";
import { Text } from "@/components/common/text";
import dayjs from "dayjs";
import advFmt from "dayjs/plugin/advancedFormat";
import { APP_NAME } from "@/config/common";
import { Alert } from "@/components/common";

dayjs.extend(advFmt);

interface ContractStatusCardProps {
  contract: Contract;
  userId: string;
}

export const ContractStatusCard = ({ contract, userId }: ContractStatusCardProps) => {
  if (contract.status === "Cancelled") {
    return (
      <Alert className="bg-red-600">
        <Text className="text-white" align="center" weight="bold" size="lg">
          Contract has been cancelled
        </Text>
      </Alert>
    );
  } else if (contract.status === "Cancelling") {
    return (
      <Alert className="bg-red-800">
        <Text className="text-white" align="center" weight="bold" size="lg">
          Cancellation in progress
        </Text>
      </Alert>
    );
  } else if (contract.status === "Completed") {
    return (
      <Alert className="bg-green-600">
        <Text className="text-white" align="center" weight="bold" size="lg">
          Contract has been completed
        </Text>
      </Alert>
    );
  } else {
    return (
      <Alert className="bg-black px-6">
        <Text className="text-gray-400 mb-1 font-[400]" size="sm">
          Next Step
        </Text>

        <Text className="text-white" weight="bold" size="lg">
          {contractNextStep(contract, userId, APP_NAME)}
        </Text>
      </Alert>
    );
  }
};
