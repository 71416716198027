import User from "./user";
import PaymentProvider from "./payment-provider";
import Country from "./country";

interface Contract {
  id: string;
  status: ContractStatus;
  buyer: ContractUser;
  seller: ContractUser;
  summary: string;
  description: string;
  country: Country;
  amount: number;
  charge: number;
  deadline: string;
  pictureUrls: string[];
  createdAt: string;
  updatedAt: string;
  payment?: ContractPayment;
  paymentAttempts: ContractPayment[];
  payouts: ContractPayout[];
  dispute?: ContractDispute;
}

export default Contract;

export interface ContractUser extends Pick<User, "id" | "name" | "email" | "country"> {
  owner: boolean;
}

export interface ContractPayment {
  ref: string;
  amount: number;
  status: ContractPaymentStatus;
  message?: string;
  createdAt: string;
  completedAt?: string;
}

export interface ContractPayout {
  user: Pick<User, "id" | "name">;
  amount: number;
  type: ContractPayoutType;
  createdAt: string;
}

export interface ContractDispute {
  status: ContractDisputeStatus;
  date: string;
  by: { id: string; name: string };
  settlementWinner?: ContractRole;
  settlementMessage?: string;
  settlementDate?: string;
  settlementBy?: Pick<User, "id" | "name">;
}

export enum ContractRole {
  BUYER = "Buyer",
  SELLER = "Seller"
}

export enum ContractStatus {
  NEW = "New",
  PAID = "Paid",
  DISPUTE = "Dispute",
  COMPLETED = "Completed",
  CANCELLING = "Cancelling",
  CANCELLED = "Cancelled"
}

export enum ContractPayoutType {
  PAYMENT = "Payment",
  REFUND = "Refund"
}

export enum ContractPaymentStatus {
  PENDING = "Pending",
  SUCCESS = "Success",
  FAILED = "Failed"
}

export enum ContractDisputeStatus {
  PENDING = "Pending",
  SETTLED = "Settled"
}

export enum DefaultContractDisputeReasons {
  NOT_DELIVERED_AFTER_DEADLINE = "The deadline is past and the seller has still not delivered",
  PRODUCT_CONTRACT_MISMATCH = "The product/service does not match the contract description",
  NOT_COMPLETED_AFTER_DELIVERY = "The buyer has not completed the contract after delivery",
  OTHER = "Other"
}

export const CONTRACT_COLLECTION_NAME = "contracts";

export const contractIsInProgress = (contract: Contract) => {
  return [ContractStatus.NEW, ContractStatus.PAID].includes(contract.status as never);
};

export const contractIsTerminal = (contract: Contract) => {
  return [
    ContractStatus.COMPLETED,
    ContractStatus.CANCELLED,
    ContractStatus.CANCELLING,
    ContractStatus.DISPUTE
  ].includes(contract.status as never);
};

export const contractCanBePaid = (contract: Contract, userId: string) => {
  return contract.status === ContractStatus.NEW && contract.buyer.id === userId;
};

export const contractIsPaid = (contract: Contract) => {
  return contract.status === ContractStatus.PAID;
};

export const contractIsInDispute = (contract: Contract) => {
  return contract.status === ContractStatus.DISPUTE;
};

export const contractUser = (contract: Contract, userId: string) => {
  const userIsBuyer = contract.buyer.id === userId;
  const user = userIsBuyer ? contract.buyer : contract.seller;
  const role = userIsBuyer ? ContractRole.BUYER : ContractRole.SELLER;
  return { role: role, name: user.name, email: user.email };
};

export const contractOtherUser = (contract: Contract, userId: string) => {
  const userIsBuyer = contract.buyer.id === userId;
  const otherUser = userIsBuyer ? contract.seller : contract.buyer;
  const otherRole = userIsBuyer ? ContractRole.SELLER : ContractRole.BUYER;
  return { role: otherRole, name: otherUser.name, email: otherUser.email };
};

export const contractNextStep = (contract: Contract, userId: string, appName: string) => {
  switch (contract.status) {
    case "New":
      if (contract.buyer.id === userId) {
        const totalAmount = contract.amount + contract.charge;
        return `Pay ${contract.buyer.country.currency} ${totalAmount.toFixed(2)} to ${appName}`;
      } else {
        return "Wait for buyer payment";
      }
    case "Paid":
      if (contract.buyer.id === userId) {
        return "Wait for delivery from seller";
      } else return "Deliver product to buyer";
    case "Dispute":
      return "Participate in the dispute process";
    default:
      return "";
  }
};

export const calculateContractCharge = async (amount: number, currency: string) => {
  const paymentProvider = await PaymentProvider.find(currency);

  if (paymentProvider) {
    return await paymentProvider.calculateCharge({ amount });
  } else return 0;
};
