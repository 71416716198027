import Country from "./country";

export enum PaymentProviderChannel {
  MOMO = "Mobile Money",
  CARD = "Card"
}

export type InitiatePaymentArgs = {
  ref: string;
  amount: number;
  email: string;
  channel: PaymentProviderChannel;
  callbackUrl: string;
  networkCode?: string;
  number?: string;
};

export type InitiatePaymentResult =
  | { channel: PaymentProviderChannel.MOMO; redirectUrl: null; message: string }
  | { channel: PaymentProviderChannel.CARD; redirectUrl: string; message: string };

export type ConfirmPaymentArgs = {
  ref: string;
};

export type ConfirmPaymentResult = {
  success: boolean;
  pending: boolean;
  failed: boolean;
  message?: string;
};

export type CalculateChargeArgs = {
  amount: number;
};

export type CalculateChargeResult = number;

export type InitiatePayoutArgs = {
  ref: string;
  amount: number;
  callbackUrl: string;
  networkCode?: string;
  number?: string;
};

export type InitiatePayoutResult = boolean;

export default abstract class PaymentProvider {
  static readonly PROFIT_PERCENT = 0.005;

  readonly id: string;
  readonly name: string;
  readonly countries: Country[];
  readonly channels: PaymentProviderChannel[];

  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(args: { id: string; name: string; countries: Country[]; channels: PaymentProviderChannel[] }) {
    this.id = args.id;
    this.name = args.name;
    this.countries = args.countries;
    this.channels = args.channels;
  }

  static async search(identifier: string) {
    const { paymentProviderKorba } = await import("./payment-provider-korba");
    const list = [paymentProviderKorba];

    return list.filter((provider) => {
      const matchingCountry = Country.find(identifier);

      return (
        identifier === provider.id ||
        identifier === provider.name ||
        (matchingCountry && provider.countries.includes(matchingCountry))
      );
    });
  }

  static async find(identifier: string) {
    const results = await this.search(identifier);
    if (results[0]) return results[0];
    return null;
  }

  abstract calculateCharge(args: CalculateChargeArgs): Promise<CalculateChargeResult>;

  abstract initiatePayment(args: InitiatePaymentArgs): Promise<InitiatePaymentResult>;

  abstract confirmPayment(args: ConfirmPaymentArgs): Promise<ConfirmPaymentResult>;

  abstract initiatePayout(args: InitiatePayoutArgs): Promise<InitiatePayoutResult>;
}
