import React, { useState } from "react";
import { BottomSheet, Button, Spinner, Tabs, Text } from "@/components/common";
import { firebaseCreateWallet, firebaseFetchWallets, firebaseSendWalletOtp } from "@/utils/firebase";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DropdownInput, TextInput } from "@/components/form";
import Network from "@/functions/src/models/network";
import { WalletItem } from "@/components/page/wallet-item";
import Wallet from "@/functions/src/models/wallet";
import Country from "@/functions/src/models/country";

interface MomoWalletSelectProps {
  country: Country;
  subtitle?: string;
  onSelect: (wallet: Wallet) => void;
  onClose: () => void;
}

const buildValidator = () => {
  return yup.object({
    networkCode: yup.string().required("Required"),
    number: yup.string().required("Required"),
    code: yup.string().required("Required")
  });
};

export const MomoWalletSelect = ({ country, subtitle, onSelect, onClose }: MomoWalletSelectProps) => {
  const [waitingForOtp, setWaitingForOtp] = useState(false);
  const entryFormInitialValues = { networkCode: "", number: "", code: "" };

  const entryForm = useForm({
    defaultValues: entryFormInitialValues,
    resolver: yupResolver(buildValidator())
  });

  const wallets = useQuery({
    queryKey: ["fetch-wallets"],
    queryFn: async () => {
      const res = await firebaseFetchWallets();
      return res.data;
    }
  });

  const sendWalletOtp = useMutation({
    mutationKey: ["send-wallet-otp"],
    mutationFn: async (args: { networkCode: string; number: string }) => {
      const res = await firebaseSendWalletOtp({
        networkCode: args.networkCode,
        number: args.number
      });
      void entryForm.trigger();
      setWaitingForOtp(true);
      return res.data;
    }
  });

  const createWallet = useMutation({
    mutationKey: ["create-wallet"],
    mutationFn: async (args: { networkCode: string; number: string; code: string }) => {
      const res = await firebaseCreateWallet({
        networkCode: args.networkCode,
        number: args.number,
        code: args.code
      });

      onSelect(res.data);
      return res.data;
    }
  });

  const handleSendWalletOtp = () => {
    buildValidator()
      .omit(["code"])
      .validate(entryForm.getValues())
      .then((input) => sendWalletOtp.mutate(input))
      .catch(() => entryForm.trigger());
  };

  const handleCreateWallet = async (values: typeof entryFormInitialValues) => {
    createWallet.mutate({
      networkCode: values.networkCode,
      number: values.number,
      code: values.code
    });
  };

  return (
    <div>
      <BottomSheet
        title="Mobile Money Details"
        subtitle={subtitle ?? "Kindly provide your mobile money details to receive the momo prompt"}
        back={onClose}>
        <Tabs
          query="momo-source"
          tabs={[
            { id: "manual", label: "Manual" },
            { id: "saved", label: "Saved" }
          ]}>
          <form className="flex flex-col gap-4 py-2" onSubmit={entryForm.handleSubmit(handleCreateWallet)}>
            <Controller
              control={entryForm.control}
              name="networkCode"
              render={({ field, fieldState }) => (
                <DropdownInput
                  label="Network"
                  options={Network.search(country.id).map((network) => [network.name, network.code])}
                  help={fieldState.error?.message}
                  error={!!fieldState.error}
                  {...field}
                />
              )}
            />

            <Controller
              control={entryForm.control}
              name="number"
              render={({ field, fieldState }) => (
                <TextInput
                  label="Momo Number"
                  placeholder="E.g. 0241234567"
                  help={fieldState.error?.message}
                  error={!!fieldState.error}
                  autoFocus
                  {...field}
                />
              )}
            />

            {waitingForOtp && (
              <Controller
                control={entryForm.control}
                name="code"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Verification Code"
                    help={fieldState.error?.message}
                    error={!!fieldState.error}
                    autoFocus
                    {...field}
                  />
                )}
              />
            )}

            {!waitingForOtp ? (
              <Button
                type="button"
                variant="secondary"
                loading={sendWalletOtp.isPending}
                onClick={handleSendWalletOtp}
                flex>
                Send Verification Code
              </Button>
            ) : (
              <Button type="submit" loading={createWallet.isPending} flex>
                Continue
              </Button>
            )}
          </form>

          <div className="flex flex-col gap-4">
            {wallets.isFetching && <Spinner size={16}>Fetching wallets...</Spinner>}

            {!wallets.isFetching && wallets.data?.length === 0 && (
              <Text align="center" className="py-8">
                No saved numbers!
              </Text>
            )}

            {wallets.data && wallets.data.length > 0 && (
              <div className="flex flex-col gap-4">
                {wallets.data.map((wallet) => (
                  <WalletItem key={wallet.id} wallet={wallet} onClick={() => onSelect(wallet)} />
                ))}
              </div>
            )}
          </div>
        </Tabs>
      </BottomSheet>
    </div>
  );
};
