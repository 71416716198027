import Country, { countryGhana } from "./country";

export default class Network {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly country: Country;

  constructor(args: { id: string; code: string; name: string; country: Country }) {
    this.id = args.id;
    this.code = args.code;
    this.name = args.name;
    this.country = args.country;
  }

  static search(identifier: string) {
    const list = [networkAirtelGh, networkMtnGh, networkVodafoneGh];

    return list.filter((network) => {
      return (
        identifier === network.id ||
        identifier === network.name ||
        identifier === network.code ||
        !!Country.find(identifier)
      );
    });
  }

  static find(identifier: string) {
    const results = this.search(identifier);
    if (results[0]) return results[0];
    return null;
  }
}

export const networkAirtelGh = new Network({
  id: "AIR_GH",
  code: "AIR",
  name: "AirtelTigo",
  country: countryGhana
});

export const networkMtnGh = new Network({
  id: "MTN_GH",
  code: "MTN",
  name: "MTN",
  country: countryGhana
});

export const networkVodafoneGh = new Network({
  id: "VOD_GH",
  code: "VOD",
  name: "Vodafone",
  country: countryGhana
});
