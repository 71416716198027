import React, { forwardRef, HTMLAttributes } from "react";
import DisputeMessage from "@/functions/src/models/dispute-message";
import { mergeClasses } from "@/utils/common";
import { PictureTile, Text } from "@/components/common";
import dayjs from "dayjs";
import { firebaseGetDownloadUrl } from "@/utils/firebase";

interface DisputeMessageItemProps extends HTMLAttributes<HTMLDivElement> {
  message: DisputeMessage;
  userId?: string;
}

export const DisputeMessageItem = forwardRef<HTMLDivElement, DisputeMessageItemProps>(
  ({ message, className, userId, ...rest }: DisputeMessageItemProps, ref) => {
    const isOwner = message.user.id === userId;
    const _className = mergeClasses("flex", isOwner && "justify-end");

    const contentClassName = mergeClasses(
      "flex",
      "flex-col",
      "p-4",
      "max-w-[85%]",
      "md:max-w-[60%]",
      "rounded-2xl",
      message.pictureUrls?.length > 0 && "gap-2",
      isOwner ? "rounded-br-none" : "rounded-bl-none",
      isOwner ? "bg-black" : "bg-gray-200",
      isOwner ? "text-white" : "text-gray-900",
      className
    );

    const metaClassName = mergeClasses(isOwner ? "text-gray-300" : "text-gray-600");

    return (
      <div ref={ref} className={_className} {...rest}>
        <div className={contentClassName}>
          {!isOwner && message.user.name && (
            <Text size="xs" className={metaClassName}>
              {message.user.name}
            </Text>
          )}

          <Text>{message.content}</Text>

          {message.pictureUrls?.length > 0 && (
            <div className="grid grid-cols-[repeat(auto-fit,minmax(96px,1fr))] gap-4">
              {message.pictureUrls.map((url) => (
                <PictureTile key={url} src={firebaseGetDownloadUrl(url)} />
              ))}
            </div>
          )}

          <Text size="xs" align="right" className={metaClassName}>
            {dayjs(message.createdAt).format("ddd MMM D, h:mmA")}
          </Text>
        </div>
      </div>
    );
  }
);
