import { ReactNode } from "react";
import { useRouter } from "next/router";
import { LinearProgress, Text } from "@/components/common";
import { AdminNavlink } from "@/components/page";
import { HiOutlineUsers } from "react-icons/hi";
import { FaFileContract } from "react-icons/fa";
import { IoPhonePortraitOutline } from "react-icons/io5";
import logoImg from "@/assets/logo.svg";
import Image from "next/image";

interface AdminLayoutProps {
  title?: ReactNode;
  loading?: boolean;
  children: ReactNode;
}

export const AdminLayout = ({ title, loading, children }: AdminLayoutProps) => {
  const router = useRouter();

  return (
    <div className="h-full flex overflow-hidden">
      <div className="min-w-[256px] h-full flex flex-col p-4 border-r-[1px] gap-6 border-gray-200 overflow-auto">
        <div className="flex gap-2 items-center pt-2 pb-4 border-b-2">
          <Image src={logoImg} height={24} alt="App logo" />
          <Text>Admin</Text>
        </div>

        <div className="flex flex-col gap-2">
          <AdminNavlink href="/admin" icon={<HiOutlineUsers />} active={router.pathname === "/admin"}>
            <Text>Users</Text>
          </AdminNavlink>

          <AdminNavlink
            href="/admin/contracts"
            icon={<FaFileContract />}
            active={router.pathname === "/admin/contracts"}>
            <Text>Contracts</Text>
          </AdminNavlink>

          <AdminNavlink href="/app" icon={<IoPhonePortraitOutline />}>
            <Text>Main App</Text>
          </AdminNavlink>
        </div>
      </div>

      <div className="flex-1 flex flex-col overflow-hidden">
        <LinearProgress show={loading} />

        <div className="flex justify-between items-center p-4 border-b-[1px] border-gray-200">{title}</div>

        <div className="flex-1 overflow-auto">{children}</div>
      </div>
    </div>
  );
};
