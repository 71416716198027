import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, forwardRef, ReactNode } from "react";
import { mergeClasses } from "@/utils/common";
import { MdChevronRight } from "react-icons/md";

type AdminNavlinkProps = LinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & {
    icon: ReactNode;
    active?: boolean;
  };

export const AdminNavlink = forwardRef<HTMLAnchorElement, AdminNavlinkProps>(
  ({ href, className, icon, children, active, ...rest }: AdminNavlinkProps, ref) => {
    const _className = mergeClasses(
      "flex",
      "justify-between",
      "items-center",
      "py-2",
      "px-4",
      "rounded",
      "hover:bg-black/10",
      active && "!bg-black text-white !py-3",
      className
    );

    return (
      <Link ref={ref} className={_className} href={href} {...rest}>
        <div className="flex items-center gap-4">
          {icon}
          {children}
        </div>
        <MdChevronRight />
      </Link>
    );
  }
);
