import React from "react";
import { Title } from "@/components/common";

interface PolicySectionProps {
  title: string;
  children: React.ReactNode;
}

export const PolicySection = ({ title, children }: PolicySectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Title>{title}</Title>
      {children}
    </div>
  );
};
