import { Children, forwardRef, HTMLAttributes, useMemo } from "react";
import { mergeClasses } from "@/utils/common";

interface MasterDetailPanelProps extends HTMLAttributes<HTMLDivElement> {}

export const MasterDetailPanel = forwardRef<HTMLDivElement, MasterDetailPanelProps>(
  ({ className, children }: MasterDetailPanelProps, ref) => {
    const [masterChild, detailChild, extraChild] = useMemo(() => {
      const _children = Children.toArray(children);
      return [_children[0], _children[1], _children[2]];
    }, [children]);

    const _className = mergeClasses("flex", "h-full", "overflow-hidden", className);

    return (
      <div ref={ref} className={_className}>
        <div className="flex-1 h-full overflow-auto">{masterChild}</div>
        <div className="h-full w-[1px] bg-gray-200" />
        <div className="w-96 h-full overflow-auto">{detailChild}</div>
        {extraChild && (
          <>
            <div className="h-full w-[1px] bg-gray-200" />
            <div className="w-96 h-full overflow-auto">{extraChild}</div>
          </>
        )}
      </div>
    );
  }
);
