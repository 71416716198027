import { Text } from "@/components/common/text";
import dayjs from "dayjs";
import Contract, { ContractPayout } from "@/functions/src/models/contract";

interface PayoutItemProps {
  contract: Contract;
  payout: ContractPayout;
}

export const PayoutItem = ({ contract, payout }: PayoutItemProps) => {
  return (
    <div className="border-2 border-gray-100 shadow-xl rounded-lg overflow-hidden flex items-center px-3 py-2 gap-2">
      <Text>
        {contract.country.currency} {payout.amount.toFixed(2)}
      </Text>

      <span className="flex-1" />

      <Text size="sm" className="text-gray-400" inline italic>
        {dayjs(payout.createdAt).format("Do MMM")}
      </Text>

      <div className="rounded-full px-3 py-1 bg-black text-white">
        <Text size="sm">{payout.type}</Text>
      </div>
    </div>
  );
};
