import React, { useEffect, useState } from "react";
import { BottomSheet, Button } from "@/components/common";
import PaymentProvider, { PaymentProviderChannel } from "@/functions/src/models/payment-provider";
import Country from "@/functions/src/models/country";

interface PaymentChannelSelectProps {
  country: Country;
  onSelect: (channel: PaymentProviderChannel) => void;
  onClose: () => void;
}

export const PaymentChannelSelect = ({ country, onSelect, onClose }: PaymentChannelSelectProps) => {
  const [channels, setChannels] = useState<PaymentProviderChannel[]>([]);

  useEffect(() => {
    PaymentProvider.find(country.id).then((provider) => setChannels(provider?.channels || []));
  }, []);

  return (
    <div>
      <BottomSheet
        title="Select preferred channel"
        subtitle="Select your preferred payment channel"
        className="flex flex-col gap-4"
        back={onClose}>
        {channels.map((channel) => (
          <Button key={channel} onClick={() => onSelect(channel)}>
            {channel}
          </Button>
        ))}
      </BottomSheet>
    </div>
  );
};
