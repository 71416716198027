import React, { forwardRef, HTMLAttributes, useMemo } from "react";
import { mergeClasses } from "@/utils/common";
import Contract, {
  contractCanBePaid,
  contractIsInDispute,
  contractIsPaid,
  contractIsTerminal
} from "@/functions/src/models/contract";
import { Button } from "@/components/common";
import numeral from "numeral";
import Link from "next/link";

interface ContractActionsPanelProps extends HTMLAttributes<HTMLDivElement> {
  contract: Contract;
  userId: string;
  loading: boolean;
  onPay: (contract: Contract) => void;
}

export const ContractActionsPanel = forwardRef<HTMLDivElement, ContractActionsPanelProps>(
  ({ contract, userId, loading, onPay, className, ...rest }: ContractActionsPanelProps, ref) => {
    const { showActions, showSecondaryActions } = useMemo(() => {
      const showActions = !contractIsTerminal(contract) || contractIsInDispute(contract);
      const showSecondaryActions = !contractIsTerminal(contract);
      return { showActions, showSecondaryActions };
    }, [contract]);

    if (!showActions) return null;

    return (
      <div ref={ref} className={mergeClasses("flex flex-col gap-4", className)} {...rest}>
        {contractCanBePaid(contract, userId) && (
          <Button loading={loading} onClick={() => onPay(contract)} flex>
            Pay {contract.country.currency} {numeral(contract.amount + contract.charge).format("0,0.00")}
          </Button>
        )}

        {contractIsPaid(contract) && contract.buyer.id === userId && (
          <Link href={`/app/contracts/${contract.id}/complete`}>
            <Button flex>Complete Contract</Button>
          </Link>
        )}

        {contractIsInDispute(contract) && (
          <Link href={`/app/contracts/${contract.id}/disputes`}>
            <Button flex>View Dispute</Button>
          </Link>
        )}

        {showSecondaryActions && (
          <div className="flex items-center gap-4">
            {contractIsPaid(contract) && (
              <Link href={`/app/contracts/${contract.id}/dispute`} className="flex-1">
                <Button variant="secondary" flex>
                  Dispute
                </Button>
              </Link>
            )}

            {!contractIsTerminal(contract) && (
              <Link href={`/app/contracts/${contract.id}/cancel`} className="flex-1">
                <Button variant="secondary" flex>
                  Cancel
                </Button>
              </Link>
            )}
          </div>
        )}
      </div>
    );
  }
);
