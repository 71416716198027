import Link, { LinkProps } from "next/link";
import Contract, {
  contractIsInDispute,
  contractIsInProgress,
  contractNextStep,
  contractUser
} from "@/functions/src/models/contract";
import { Text } from "@/components/common/text";
import dayjs from "dayjs";
import advFmt from "dayjs/plugin/advancedFormat";
import { APP_NAME } from "@/config/common";
import { mergeClasses } from "@/utils/common";

dayjs.extend(advFmt);

interface ContractItemProps extends Omit<LinkProps, "href"> {
  contract: Contract;
  userId: string;
}

export const ContractItem = ({ contract, userId }: ContractItemProps) => {
  const statusClassName = mergeClasses(
    "rounded-full",
    "py-0.5",
    "px-2",
    "bg-gray-200",
    contract.status === "Paid" && "!bg-orange-200",
    contract.status === "Dispute" && "!bg-blue-600 !text-white",
    contract.status === "Completed" && "!bg-green-600 !text-white",
    contract.status === "Cancelling" && "!bg-red-600 !text-white",
    contract.status === "Cancelled" && "!bg-red-600 !text-white"
  );

  return (
    <Link
      href={`/app/contracts/${contract.id}`}
      className="flex flex-col border-2 border-gray-100 shadow-xl bg-white rounded-lg p-3 gap-2">
      <Text>{contract.summary}</Text>

      <div className="flex items-center gap-2">
        <div className={statusClassName}>
          <Text size="sm">{contract.status}</Text>
        </div>

        <div className="bg-gray-200 rounded-full py-0.5 px-2">
          <Text size="sm">{dayjs(contract.deadline).format("Do MMM YY")}</Text>
        </div>

        <div className="bg-gray-200 rounded-full py-0.5 px-2">
          <Text size="sm">I&apos;m the {contractUser(contract, userId).role.toLowerCase()}</Text>
        </div>
      </div>

      {(contractIsInProgress(contract) || contractIsInDispute(contract)) && (
        <Text>
          <Text className="text-gray-600" inline>
            Next -
          </Text>{" "}
          <Text inline>{contractNextStep(contract, userId, APP_NAME)}</Text>
        </Text>
      )}
    </Link>
  );
};
