import { Children, ReactNode, useEffect, useMemo } from "react";
import {
  Alert,
  AlertProps,
  AppIcon,
  Button,
  Container,
  ContainerProps,
  Img,
  LinearProgress,
  PopupMenu,
  Text,
  Title
} from "@/components/common";
import Link from "next/link";
import Popup from "reactjs-popup";
import Image from "next/image";
import leftArrow from "@/assets/left-arrow.svg";
import profileImg from "@/assets/profile.svg";
import { useAuthContext } from "@/contexts";
import { APP_NAME } from "@/config/common";
import { firebaseGetDownloadUrl, firebaseLogout } from "@/utils/firebase";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { UserStatus } from "@/functions/src/models/user";
import menuImg from "@/assets/menu.svg";
import { mergeClasses } from "@/utils/common";
import { queryClient } from "@/config/react-query";

interface AppLayoutProps {
  title: string;
  alert?: Pick<AlertProps, "type" | "icon" | "children">;
  back?: string | true;
  loading?: boolean;
  hideMenu?: boolean;
  children: ReactNode;
}

const AppLayout = ({ title, alert, back, loading, hideMenu, children }: AppLayoutProps) => {
  const router = useRouter();
  const { user } = useAuthContext();

  const { header, body, footer } = useMemo(() => {
    const _children = Children.toArray(children);
    const header = _children.find((child) => (child as any)?.type === _AppLayoutHeader);
    const body = _children.find((child) => (child as any)?.type === _AppLayoutBody);
    const footer = _children.find((child) => (child as any)?.type === _AppLayoutFooter);
    return { header, body, footer };
  }, [children]);

  const handleLogout = () => {
    firebaseLogout().then(() => toast("Logged out"));
    void queryClient.removeQueries();
    void router.push(`/login?next=${encodeURIComponent(router.asPath)}`);
  };

  useEffect(() => {
    if (user.email && user.status === UserStatus.NEW && !router.pathname.startsWith("/app/verify")) {
      void router.push("/app/verify/start");
    }
  }, [user, router.pathname]);

  return (
    <div className="h-full flex flex-col overflow-hidden gap-2">
      <LinearProgress className="fixed left-0 top-0 w-full z-10" show={loading} />

      <nav>
        <Container className="flex justify-between items-center gap-4 py-4">
          {typeof back === "string" ? (
            <Link href={back}>
              <Image src={leftArrow} alt="back" />
            </Link>
          ) : back === true ? (
            <button onClick={() => router.back()}>
              <Image src={leftArrow} alt="back" />
            </button>
          ) : (
            <AppIcon size={24} />
          )}

          <Title className="!font-[500] h-8 truncate">{title}</Title>

          <div className="w-6">
            {!hideMenu && (
              <Popup
                position="bottom right"
                offsetX={20}
                offsetY={-50}
                trigger={
                  <Button type="button" variant="link" className="!px-0" flex>
                    <Image src={menuImg} alt="Options" />
                  </Button>
                }>
                <PopupMenu className="p-4 gap-3">
                  <Text align="center" className="font-bold">
                    {APP_NAME}
                  </Text>

                  <div className="rounded-full grid place-itemvoid s-center w-14 h-14 mx-auto overflow-hidden">
                    <Img
                      src={user.pictureUrl ? firebaseGetDownloadUrl(user.pictureUrl) : profileImg}
                      className="w-full"
                      alt="Profile"
                    />
                  </div>

                  <div>
                    <Text align="center" className="font-bold ">
                      {user.name}
                    </Text>

                    <Text align="center" size="sm">
                      {user.email}
                    </Text>
                  </div>

                  <div className="flex flex-col gap-2">
                    <Link href="/app/profile">
                      <Button type="button" flex>
                        Profile
                      </Button>
                    </Link>

                    {user.isAdmin && (
                      <Link href="/admin">
                        <Button type="button" flex>
                          Admin Console
                        </Button>
                      </Link>
                    )}

                    <Button type="button" flex onClick={handleLogout}>
                      Logout
                    </Button>
                  </div>
                </PopupMenu>
              </Popup>
            )}
          </div>
        </Container>
      </nav>

      {alert && (
        <Alert type={alert.type} icon={alert.icon} className="rounded-none !px-0">
          <Container>{alert.children}</Container>
        </Alert>
      )}

      {header}
      {body}
      {footer}
    </div>
  );
};

const hasChildren = (children: ReactNode) => {
  return Children.toArray(children).filter((child) => !!child).length > 0;
};

const _AppLayoutHeader = ({ children, ...rest }: ContainerProps) => {
  if (!hasChildren(children)) return null;

  return (
    <Container as="header" {...rest}>
      {children}
    </Container>
  );
};

const _AppLayoutBody = ({ children, className, ...rest }: ContainerProps) => {
  // if (!hasChildren(children)) return null;

  return (
    <div className={mergeClasses("flex-1", className)} {...rest}>
      {children}
    </div>
  );
};

const _AppLayoutFooter = ({ children, className, ...rest }: ContainerProps) => {
  if (!hasChildren(children)) return null;

  return (
    <Container as="footer" className={mergeClasses("pb-4", className)} {...rest}>
      {children}
    </Container>
  );
};

const AppLayoutNamespace = Object.assign(AppLayout, {
  Header: _AppLayoutHeader,
  Body: _AppLayoutBody,
  Footer: _AppLayoutFooter
});

export { AppLayoutNamespace as AppLayout };
