import { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

interface GradientBoxProps extends HTMLAttributes<HTMLDivElement> {}

export const GradientBox = forwardRef<HTMLDivElement, GradientBoxProps>(
  ({ className, children, ...rest }: GradientBoxProps, ref) => {
    const _className = mergeClasses(
      "rounded-lg",
      "bg-gradient-to-b",
      "from-black",
      "to-[#24162d]",
      "lg:to-[#341E3F]",
      className
    );

    return (
      <div ref={ref} className={_className} {...rest}>
        {children}
      </div>
    );
  }
);
