import { Text, Title } from "@/components/common";
import { ReactNode } from "react";

interface HowItWorksItemProps {
  step: number;
  title: string;
  description: ReactNode;
}

export const HowItWorksItem = ({ step, title, description }: HowItWorksItemProps) => {
  return (
    <div className="relative">
      <div className="w-12 h-12 grid place-items-center rounded-full bg-white border-2 border-black mx-auto opacity-[0.99]">
        <Text size="lg">{step}</Text>
      </div>

      <div className="flex flex-col gap-4 pt-8 pb-6 px-8 -mt-6 rounded-xl bg-gradient-to-br from-black to-[#424242] shadow-xl">
        <Title className="text-white text-[24px]">{title}</Title>
        <Text className="text-white">{description}</Text>
      </div>
    </div>
  );
};
