import Image from "next/image";
import shieldImg from "@/assets/shield.svg";
import storeFrontImg from "@/assets/store-front.svg";
import burglarImg from "@/assets/burglar.svg";
import { Text, Title } from "@/components/common";
import { APP_NAME } from "@/config/common";

export enum FeatureCardContent {
  BUYER_PROTECTION = "Buyer Protection",
  SELLER_PROTECTION = "Seller Protection",
  FRAUD_PREVENTION = "Fraud Prevention"
}

interface FeatureCardProps {
  feature: FeatureCardContent;
}

export const FeatureCard = ({ feature }: FeatureCardProps) => {
  const featureIcon = (feature: FeatureCardContent) => {
    switch (feature) {
      case FeatureCardContent.BUYER_PROTECTION:
        return shieldImg;
      case FeatureCardContent.SELLER_PROTECTION:
        return storeFrontImg;
      case FeatureCardContent.FRAUD_PREVENTION:
        return burglarImg;
      default:
        return null;
    }
  };

  const featureContent = (feature: FeatureCardContent) => {
    switch (feature) {
      case FeatureCardContent.BUYER_PROTECTION:
        return (
          <>
            Only transact with sellers with verified government identities (E.g. Ghana Card, Driver&apos;s License).
            Your payment is then held securely by {APP_NAME} and sent to your seller when you have received your
            product.
          </>
        );
      case FeatureCardContent.SELLER_PROTECTION:
        return (
          <>
            Your buyer pays to {APP_NAME}. Work with the peace of mind that you will be paid at the end of your
            contract. When disputes arise with your buyer, simply reach out to us and we will help resolve it very
            quickly.
          </>
        );
      case FeatureCardContent.FRAUD_PREVENTION:
        return (
          <>
            All {APP_NAME} users are verified by taking a picture of a{" "}
            <Text weight="bold" inline>
              valid ID card
            </Text>{" "}
            and a{" "}
            <Text weight="bold" inline>
              selfie
            </Text>{" "}
            which is checked with a government database. Payments are also securely held with trusted institutions
            within your country.
          </>
        );
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center lg:max-w-[360px] bg-white px-8 py-10 rounded-xl shadow-xl">
      <Image src={featureIcon(feature)} height={72} alt={feature} />

      <Title size="lg">{feature}</Title>

      <Text className="opacity-90">{featureContent(feature)}</Text>
    </div>
  );
};
