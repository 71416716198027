import Wallet from "@/functions/src/models/wallet";
import { Text } from "@/components/common/text";
import { Button, PopupMenu } from "@/components/common";
import Popup from "reactjs-popup";
import dotsImg from "@/assets/dots.svg";
import Image from "next/image";
import { HTMLAttributes } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { mergeClasses } from "@/utils/common";

interface WalletItemProps extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  wallet: Wallet;
  busy?: boolean;
  selected?: boolean;
  onDelete?: (wallet: Wallet) => void;
}

export const WalletItem = ({ busy, wallet, selected, onDelete, className, ...rest }: WalletItemProps) => {
  const _className = mergeClasses(
    "flex",
    "items-center",
    "border-2",
    "border-gray-100",
    "shadow-xl",
    "rounded-lg",
    "p-3",
    "gap-2",
    rest.onClick && "hover:cursor-pointer",
    className
  );

  return (
    <div className={_className} {...rest}>
      <Text>
        {wallet.number} ({wallet.network.name})
      </Text>

      <span className="flex-1" />

      {onDelete && (
        <Popup
          position="bottom right"
          trigger={
            <Button variant="link" className="!py-1 !px-2 flex items-center gap-2" loading={busy}>
              <Image src={dotsImg} alt="Options" />
            </Button>
          }>
          {/* @ts-ignore */}
          {(close) => {
            return (
              <PopupMenu className="!w-auto !border-none">
                {onDelete && (
                  <Button
                    variant="link"
                    className="!justify-between text-red-600"
                    onClick={() => {
                      onDelete(wallet);
                      close();
                    }}>
                    Delete
                  </Button>
                )}
              </PopupMenu>
            );
          }}
        </Popup>
      )}

      {selected && <FaCircleCheck />}
    </div>
  );
};
